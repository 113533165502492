import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionnaireService } from './questionnaire.service';
import { ToastService } from '@capturum/ui/api';
import { ServiceType } from '../models/service-type.model';
import {AppRoutes} from "@core/enums/routes.enum";

@Injectable()
export class QuestionnaireWizardService {
  public totalSteps = location.hostname.includes('airco') ? 2 : 4;
  public currentWizardTypeSubject: BehaviorSubject<string> = new BehaviorSubject('');

  public ownerShipInformationForm: FormGroup;
  public houseTypeForm: FormGroup;
  public contactForm: FormGroup;
  public heatPompInformationForm: FormGroup;
  public projectInformationForm: FormGroup;

  public activeStep = 1;

  public get progress(): number {
    return (this.activeStep / this.totalSteps) * 100;
  }

  constructor(
    private readonly router: Router,
    private readonly _fb: FormBuilder,
    private readonly _questionnaireService: QuestionnaireService,
    private readonly toastService: ToastService
  ) {
    this._initStepForms();
  }

  private _initStepForms(): void {
    this.ownerShipInformationForm = this._fb.group({
      preferred_installation_period: new FormControl(),
      type: ['', Validators.required],
      room_type: new FormControl(),
    });

    this.houseTypeForm = this._fb.group({
      houseTypeControl: ['', Validators.required],
    });

    this.contactForm = this._fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      zip_code: ['', Validators.required],
      city: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      remark: [''],
      privacyPolicy: [false, Validators.requiredTrue],
    });

    this.heatPompInformationForm = this._fb.group({
      gas_consumption: new FormControl(''),
      power_consumption: new FormControl(''),
      construction_year: new FormControl(),
      current_distribution_system: new FormControl(),
      living_space: new FormControl(''),
      preferred_installation_period: new FormControl(),
    });

    this.projectInformationForm = this._fb.group({
      private_or_business: new FormControl(),
      current_heating: new FormControl(),
      number_of_residents: new FormControl(),
      heat_pump_preference: new FormControl(),
      degree_of_insulation: new FormControl(),
    });
  }

  public goBack(step?: string, stepNumber?: number): void {
    this.activeStep = stepNumber;
    this.router.navigate([`${AppRoutes.questionnaire}/${step}`]);
  }

  public goTo(urlSeg: string, stepNumber?: number): void {
    this.activeStep = stepNumber;
    this.router.navigate([`${AppRoutes.questionnaire}/${urlSeg}`]);
  }

  public submitQuestionnaire(): void {
    const formValues = {
      ...(this.currentWizardTypeSubject.getValue() === 'airco'
        ? { ...this.ownerShipInformationForm.value, ...this.contactForm.value }
        : {
            ...this.houseTypeForm.value,
            ...this.contactForm.value,
            ...this.heatPompInformationForm.value,
            ...this.projectInformationForm.value,
          }),
      language: 'be-NL',
      service_type: this.currentWizardTypeSubject.getValue() === 'airco' ? ServiceType.AIRCO : ServiceType.HEATPUMP,
    };

    this._questionnaireService.submitQuestionnaire(formValues).subscribe({
      next: () => {
        this.toastService.success('Succes', 'Het formulier is succesvol verzonden');

        this.goTo('final');
      },
    });
  }
}
