<div class="step-container">
  <div class="step-content">
    <div class="text-section">
      <h1 class="step-title">Projectinformatie</h1>
      <p class="step-description">
        Selecteer de opties die het beste bij uw situatie passen:
      </p>
    </div>

    <div class="container-fluid px-0">
      <ng-container [formGroup]="projectInformationForm">
        <div class="row">
          <div class="col-md-4 control-label">
            <div>Particulier of zakelijk?</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="privacyOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="private_or_business"
              [sortAlphabetically]="false"
            >
            </cap-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 control-label">
            <div>Huidig ​​verwarmingssysteem</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="currentHeatingOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="current_heating"
              [sortAlphabetically]="false">
            </cap-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 control-label">
            <div>Aantal bewoners</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="numberOfResidentsOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="number_of_residents"
              [sortAlphabetically]="false">
            </cap-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 control-label">
            <div>Voorkeurstype warmtepomp</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="preferredHeatPumpOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="heat_pump_preference"
              [sortAlphabetically]="false">
            </cap-dropdown>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 control-label">
            <div>Mate van isolatie</div>
          </div>

          <div class="col-md-8 control-field">
            <cap-dropdown
              placeholder="Selecteer"
              [autoDisplayFirst]="false"
              [options]="degreeOfInsulationOptions"
              appendTo="body"
              class="dropdown-control"
              formControlName="degree_of_insulation"
              [sortAlphabetically]="false">
            </cap-dropdown>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="button-container">
    <app-back-button (navigationEvent)="back()"></app-back-button>

    <app-next-button (navigationEvent)="next()" [disabled]="projectInformationForm.invalid"></app-next-button>
  </div>
</div>
