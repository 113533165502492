import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PublicDropdownsService {
  public getTypeOfPropertyOptions(): { label: string; value: string }[] {
    return [
      { label: 'Project in New Build', value: 'new_build' },
      { label: 'Renovation', value: 'renovation' },
    ];
  }

  public getCurrentHeatingOptions(): { label: string; value: string }[] {
    return [
      { label: 'Centrale verwarming', value: 'ch' },
      { label: 'Elektrisch', value: 'electric' },
      { label: 'Warmtepomp', value: 'heat_pump' },
      { label: 'Geen', value: 'none' },
      { label: 'Houtkachel', value: 'wood_Stove' },
    ];
  }

  public getPrivacyOptions(): { label: string; value: string }[] {
    return [
      { label: 'Particulier', value: 'private' },
      { label: 'Zakelijk', value: 'business' },
    ];
  }

  public getNumberOfResidentsOptions(): { label: string; value: string }[] {
    return [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },
    ];
  }

  public getPreferredHeatPumpOptions(): { label: string; value: string }[] {
    return [
      { label: 'Hoge temperatuur', value: 'high_temp' },
      { label: 'Hybride', value: 'hybrid' },
      {
        label: 'Hybride en nieuwe boiler',
        value: 'hybrid_new_boiler',
      },
      { label: 'Lage temperatuur', value: 'low_temp' },
    ];
  }

  public getDegreeOfInsulationOptions(): { label: string; value: string }[] {
    return [
      { label: 'Slecht geïsoleerd', value: 'poor' },
      { label: 'Redelijk geïsoleerd', value: 'moderate' },
      { label: 'Goed geïsoleerd', value: 'good' },
    ];
  }

  public getYearOfConstructionOptions(): { label: string; value: string }[] {
    return [
      { label: 'Vóór 1980', value: 'before_1980' },
      { label: '1980 - 1990', value: '1980_1990' },
      { label: '1990 - 2000', value: '1990_2000' },
      { label: '1996 - 2005', value: '1996_2005' },
      { label: '2000 - 2010', value: '2000_2010' },
      { label: '2006 - 2010', value: '2006_2010' },
      { label: 'Na 2010', value: 'after_2010' },
      { label: 'Weet ik niet', value: 'unknown' },
    ];
  }

  public getDeliverySystemOptions(): { label: string; value: string }[] {
    return [
      {
        label: 'Ventilatorconvector',
        value: 'fan_convector',
      },
      { label: 'Radiatoren', value: 'radiator' },
      { label: 'Vloerverwarming', value: 'under_floor' },
      {
        label: 'Vloerverwarming en convector',
        value: 'under_floor_and_fan_convector',
      },
      {
        label: 'Vloerverwarming en radiatoren',
        value: 'under_floor_and_radiator',
      },
    ];
  }

  public getHouseUpgradeOptions(): { label: string; value: string }[] {
    return [
      { label: 'No house upgrade', value: 'no_upgrade' },
      { label: 'Partially upgraded', value: 'partial_upgrade' },
      { label: 'Fully upgraded', value: 'full_upgrade' },
    ];
  }
}
