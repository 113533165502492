import { Component, ViewEncapsulation } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-editable-form-field-wrapper',
  templateUrl: './editable-form-field-wrapper.component.html',
  styleUrls: ['./editable-form-field-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditableFormFieldWrapperComponent extends FieldWrapper {}
