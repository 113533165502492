<div class="text-section">
  <h1 class="step-title">Projectinformatie</h1>
  <p class="step-description">
    Selecteer de optie die het beste bij uw situatie past:
  </p>
</div>

<div class="home-type-section">
  <h2 class="section-title">Type woning *</h2>
  <div class="container-fluid p-0">
    <div class="row">
      <div
        class="col-6 col-md-4 mb-3"
        *ngFor="let option of houseTypeOptions"
        (click)="selectOption.emit(option.value)">
        <div class="tile" [class.selected]="houseTypeForm.controls.houseTypeControl.value === option.value">
          <img [src]="option.icon" alt="{{ option.label }}" />
        </div>
        <div class="tile-label">{{ option.label }}</div>
      </div>
    </div>
  </div>
</div>
