import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { QuestionnaireWizardService } from '../../services/questionnaire-wizard.service';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { PrivateBusiness } from '../../models/private-business.model';

@Component({
  selector: 'app-general-information-step',
  templateUrl: './general-information-step.component.html',
  styleUrls: ['./general-information-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GeneralInformationStepComponent implements OnInit {
  public installationDateOptions = [
    { label: '0-3 maanden', value: '0_3' },
    { label: '3-6 maanden', value: '3_6' },
    { label: '6-9 maanden', value: '6_9' },
    { label: '9-12 maanden', value: '9_12' },
    {
      label: 'Later dan 12 maanden',
      value: '12_later',
    },
  ];

  public houseTypeOptions = [
    {
      value: 'apartment',
      label: 'Appartement',
      icon: './assets/images/apartment.svg',
    },
    {
      value: 'corner_house',
      label: 'Hoekhuis',
      icon: './assets/images/corner_house.png',
    },
    {
      value: 'detached',
      label: 'Vrijstaand huis',
      icon: './assets/images/house.svg',
    },
    {
      value: 'semi-detached',
      label: 'Twee onder een kap',
      icon: './assets/images/Semi-detached.png',
    },
    {
      value: 'terraced',
      label: 'Rijtjeshuis',
      icon: './assets/images/terraced.png',
    },
  ];

  public roomTypeOptions = {
    [PrivateBusiness.PRIVATE]: [
      { label: 'Slaapkamer', value: 'bedroom' },
      { label: 'Woonkamer', value: 'living-room' },
      { label: 'Slaapkamer & woonkamer', value: 'bedroom-living-room' },
      { label: 'Keuken', value: 'kitchen' },
      { label: 'Anders', value: 'other' },
    ],
    [PrivateBusiness.BUSINESS]: [
      { label: 'Kantoor', value: 'office' },
      { label: 'Winkel', value: 'store' },
      { label: 'Kapsalon', value: 'salon' },
      { label: 'Anders', value: 'other' },
    ],
  };

  public options = [];
  public ownerShipInformationForm: FormGroup;
  public houseTypeForm: FormGroup;
  public selectedWizardType: Observable<string> = new Observable();

  constructor(
    private readonly _questionnaireWizardSvc: QuestionnaireWizardService
  ) {}

  public ngOnInit(): void {
    this._questionnaireWizardSvc.activeStep = 1;
    this._initCurrentQuestionnaireType();
    this._initQuestionnaireForm();
    this.selectedWizardType =
      this._questionnaireWizardSvc.currentWizardTypeSubject.asObservable();
  }

  public selectOption(value: string): void {
    const { houseTypeControl } =
      this._questionnaireWizardSvc.houseTypeForm.controls;

    houseTypeControl.setValue(value);
  }

  private _initQuestionnaireForm(): void {
    this.ownerShipInformationForm =
      this._questionnaireWizardSvc.ownerShipInformationForm;
    this.houseTypeForm = this._questionnaireWizardSvc.houseTypeForm;
  }

  public moveOnNextStep(): void {
    if (
      this._questionnaireWizardSvc.currentWizardTypeSubject.getValue() ===
      'airco'
    ) {
      this._questionnaireWizardSvc.goTo('contact', 2);
    } else {
      this._questionnaireWizardSvc.goTo('step-2', 2);
    }
  }

  private _initCurrentQuestionnaireType(): void {
    this._questionnaireWizardSvc.currentWizardTypeSubject.next(
      location.hostname.includes('airco') ? 'airco' : 'warmtepomp'
    );
  }

  public next(): void {
    this._questionnaireWizardSvc.goTo('step-2');
  }
}
